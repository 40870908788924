import React from "react"
import { useIntl, Link } from "gatsby-plugin-intl"

import SEO from "../components/seo"
import FlowIndicator from "../components/hoc/flowIndicator/flowIndicator"

const Cancel = () => {

    const i18n = useIntl().formatMessage

    return (
        <>
        <SEO title="Din betaling blev annulleret" />
        <section className="section-padding-big">
            <div className="container">
                <div className="row center-xs">
                <div className="col col-xs-12 col-md-10 col-lg-8 col-xl-6">
                    <h1 className="text-center h2">{ i18n({ id: "pages.register.title" })}</h1>
                    <p className="text-center" >{ i18n({ id: "pages.register.description" })}</p>
                       <FlowIndicator  steps={ [
                            {name: i18n({ id: "pages.register.steps.registration" }), active: false, complete: true},
                            {name: i18n({ id: "pages.register.steps.info" }), active: false, complete: true},
                            {name: i18n({ id: "pages.register.steps.design" }), active: false, complete: true},
                            {name: i18n({ id: "pages.register.steps.payment" }), active: true, complete: true}
                        ]}/>

                    <div className="card text-center">
                        <h3>Din betaling blev annulleret</h3>
                        <p>Din side er først live når din betaling går igennem. Hvis vi ikke modtager din betaling inden for 30 dage bliver din side automatisk slettet.</p>
                        <p>Du kan opdatere dine betalingsoplysninger under konto.</p>
                        <Link to="/app/account" className="btn">Ret betalingsoplysninger</Link>
                    </div>
                    </div>
                </div>
            </div>
        </section>
    </>
    )
}

export default Cancel
